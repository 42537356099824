// @mui
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TextSection from "src/components/TextSection";
import HeroSmall from "src/sections/HeroSmall";
import Page from "../components/Page";

import { SectionContact } from "../sections";

const ContentStyle = styled("div")(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  backgroundColor: theme.palette.background.default,
  zIndex: 10,
}));

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: theme.spacing(14),
  [theme.breakpoints.up("lg")]: {
    textAlign: "left",
    paddingTop: theme.spacing(14),
  },
}));
// ----------------------------------------------------------------------
const data = [
  {
    lesson: "Zwemles",
    day: "Zaterdag",
    start: "07:30",
    end: "15:00",
    location: "Helsdingen Vianen",
  },
  {
    lesson: "Recreatief zwemmen (buiten schoolvakantie)",
    day: "Woensdag",
    start: "14:00",
    end: "15:00",
    location: "Helsdingen Vianen",
  },
];

export default function LessenPage() {
  return (
    <Page title="Zwemschool Midden | Lessen">
      <HeroSmall
        subtitleProps={{
          subtitle1: "Lessen",
          subtitle2: "Locaties",
        }}
        imageUrl="/assets/images/temp-1.jpeg"
      />
      <ContentStyle>
        <RootStyle>
          <TextSection
            title="A-Diploma behalen"
            content={[
              "Zo’n 75% van de kinderen behaald binnen 12 maanden hun A-diploma. Bewust leggen wij de lat bij A-diploma iets lager, veiligheid in acht nemend en bij het B-diploma trekken wij dat dan weer recht",
              "Na het behalen van het A-diploma gaan de kinderen zelfverzekerd het B-traject in. Wij kunnen u en uw kind niet veranderen. Maar wij kunnen zelf wel veranderen en dat doen wij dan ook. Positieve support werkt!",
            ]}
          />

          <TextSection title="Lessen, tijden & locatie" content={[
            " - Startersgroepen vinden enkel in de ochten plaats tot 10.30 - "
          ]} />

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ maxWidth: "100%", overflow: "auto", mt: -6 }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 720, overflow: "auto", maxWidth: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Les</TableCell>
                    <TableCell align="center">Dag</TableCell>
                    <TableCell align="center">Tijd</TableCell>
                    <TableCell align="center">Vestiging</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <strong>{row.lesson}</strong>
                      </TableCell>
                      <TableCell align="center">{row.day}</TableCell>
                      <TableCell align="center">
                        {row.start} {row.start ? "-" : ""} {row.end}
                      </TableCell>
                      <TableCell align="center">{row.location}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Box sx={{ mt: 10, mb: 0, textAlign: "center", width: "100%" }}>
            <SectionContact
              gray
              subtitle="Neem gerust contact op, bij voorkeur middels email"
            />
          </Box>
        </RootStyle>
      </ContentStyle>
    </Page>
  );
}
