// @mui
import { styled } from '@mui/material/styles';
import { Grid, Divider, Container, Typography, Stack, Box } from '@mui/material';
import SocialsButton from '../../components/SocialsButton';
import { DualLogo } from 'src/components/Logo';
import { isMobile } from 'src/utils/isMobile';
// ----------------------------------------------------------------------
const LINKS = [
  {
    headline: 'Nieuwsbrief',
    children: [
      { name: 'Zomer 2024', href: '/assets/Nieuwsbrief_ZOMER_2024.docx' },
      { name: 'Winter 2024', href: '/assets/Nieuwsbrief_WINTER_2024.pdf' },
      { name: 'Watergewenning & zwemadvies', href: '/assets/Watergewenning-en-zwemadvies-2025.docx' },
    ],
  },
  {
    headline: 'Bedrijf',
    children: [
      { name: 'VOF Zwemschool Midden', href: undefined },
      { name: 'KvK: 87802716', href: undefined },
      { name: 'Algemene voorwaarden', href: '/assets/legal/Algemene_voorwaarden_2023_2024.docx' },
      { name: 'Privacy statement', href: '/assets/legal/Privacystatement.docx' }
    ],
  },
  {
    headline: 'Links',
    children: [
      { name: 'Home', href: '/' },
      { name: 'Werkwijze', href: '/#/Werkwijze' },
      { name: 'Lessen', href: '/#/Lessen' },
      { name: 'Kosten', href: '/#/Kosten' },
      { name: 'Inschrijven', href: '/#/Inschrijven' },
      { name: 'Gallerij', href: '/#/Gallerij' },
      { name: 'Contact', href: '/#/Contact' },
      { name: 'Vacatures', href: '/#/Vacatures' },
      { name: 'FAQ', href: '/#/FAQ' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const mobile = isMobile();

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: mobile ? 5 : 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={8} md={2}>
            {
              !mobile && <DualLogo size={50} />
            }
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'center' }}
              sx={{ mt: mobile ? 0 : 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={9}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="end"
            >
              {LINKS.map((list, index) => (
                <Stack key={index} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link, ii) => (
                    <a key={ii} style={{ textDecoration: 'none', color: '#637381' }} href={link.href}>
                      <Typography component="p" variant="overline" sx={{ m: 0, color: 'text.disabled', textTransform: 'none' }}>
                        {link.name}
                      </Typography>
                    </a>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © {new Date(Date.now()).getFullYear()}. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
